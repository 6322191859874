@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

.sectionsss{
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: url(booking.jpg)no-repeat;
    background-size: cover;
    background-position: center;
}

header{
    position: relative;
    top: 0;
    width: 100%;
    padding: 30px 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header .log {
    position: relative;
    color: #e00707;
    font-size: 30px;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 1px;
    cursor: pointer;
}

header .navigation a{
    color: #000;
    text-decoration: none;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    padding: 2px 15px;
    border-radius: 20px;
    transition: 0.3s;
    transition-property: background;
}

header .navigation a:not(:last-child){
    margin-right: 30px;
}

header .navigation a:hover{
    background: #fff;
}

.conten{
    max-width: 650px;
    margin: 60px 100px;
    
}

.conten .info h2{
    color: #226A80;
    font-size: 55px;
    text-transform: uppercase;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 60px;
    margin-bottom: 30px;
}

.conten .info h2 span{
    color: #db6868;
    font-size: 50px;
    font-weight: 600;
}
.paragraph{
    color: #fff;
}
.content .info p{
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 40px;
    color: #fff;
}

 

.conten .info-btn {
    color: #fff;
    background: #226A80;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    padding: 10px 20px;
    border-radius: 5px;
    transition: 0.3s;
    margin-left: 20px;
    cursor: pointer;
    transition-property: background;
}

.conten .info-btn:hover{
    background: #0C4F60;
}

.media-icons{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.media-icons a{
    position: relative;
    color: #fff;
    font-size: 25px;
    transition: 0.3s;
    transition-property: transform;
}

.media-icons a:not(:last-child){
    margin-right: 60px;
}

.media-icons a:hover{
    transform: scale(1.5);
}

.label {
    display: none;
}
#check {
    z-index: 3;
    display: none;
}

@media (max-width: 960px){

    .conten .info-btn{
        display: block;
        margin: 15px;
    }
    
    header .navigation{
        display: none;
    }

    .label {
        display: block;
        font-size: 25;
        cursor: pointer;
        transition: 0.3s;
        transition-property: color;
    }

    .label .close-btn:hover{
        color: #fff;
    }
    .label .menu-btn:hover{
        color: #fff;
    }
    .label .close-btn{
        display: none;
        color: #000;
    }

    #check:checked ~ header .navigation{
        z-index: 2;
        position: fixed;
        background-color: rgba(114, 223, 255, 0.9);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #check:checked ~ header .navigation a{
        font-weight: 700;
        margin-right: 0;
        margin-bottom: 50px;
        letter-spacing: 2px;
    }

    #check:checked ~ header .label .menu-btn{
        display: none;
        text-decoration: none;
    }

    #check:checked ~ header .label .close-btn{
        z-index: 2;
        display: block;
        position: fixed;
    }

    .label .menu-btn{
        position: absolute;
        color: #000;
    }

    header .log {
        position: absolute;
        bottom: -6px;
    }
    .conten .info h2{
        font-size: 45px;
        line-height: 50px;
    }

    .conten .info h2 span{
        font-size: 40px;
        font-weight: 600;
    }

    .conten .info p{
        font-size: 14px;
    }

}

@media (max-width: 560px){
    .conten .info h2{
        font-size: 35px;
        line-height: 40px;
    }

    .conten .info h2 span{
        font-size: 30px;
        font-weight: 600;
    }

    .conten .info p{
        font-size: 14px;
    }
}