@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Titillium Web", sans-serif;
}

li {
  text-decoration: none;
  list-style: none;
}

.navbar {
  line-height: 10vh;
  height: 0vh;
  box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 30px;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  width: 75%;
}

/* .container {
    max-width: 90%;
    margin: auto;
} */

.nav-links li {
  color: black;
  font-size: 20px;
  padding: 0 10px;
  margin: 0 10px;
  cursor: pointer;
  text-transform: capitalize;
}

button {
  border: none;
  background: none;
  font-size: 20px;
}

.mobile-menu-icon {
  display: none;
}
/*Landing page*/

.Logo-img {
  width: 60px;
}
.Drink-img {
  width: 100%;
  height: 750px;
}
.Styles-text {
  position: absolute;
  top: 20%;
  color: #1a54b0;
  padding: 20px;
  text-align: left;
  font: normal normal 800 75px/75px Proxima Nova;
  letter-spacing: 1px;
  text-transform: uppercase;
  opacity: 0.87;
}

.hospitality-text {
  position: absolute;
  margin-top: 10px;
  top: 32%;
  padding: 20px;
  text-align: left;
  font: normal normal 800 40px/38px Proxima Nova;
  letter-spacing: 1.6px;
  color: #ff0050;
  text-transform: uppercase;
  opacity: 0.87;
}

.ContactUs-btn {
  margin-top: 4%;
  margin-left: 1%;
  position: absolute;
  cursor: pointer;
  top: 41%;
  color: #fff;
  font-family: bold;
  padding: 20px;
  border: 2px solid var(--unnamed-color-ff0050);
  background: #ff00504d 0% 0% no-repeat padding-box;
  border: 2px solid #ff0050;
  opacity: 1;
}

.container-2 {
  position: inherit;
}
/*text nex ot image of what we do best*/
.container-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  background-color: #0a3c63;
  
  
}
.container-1 .wrapper {
  margin: 150px auto;
  width: 70%;
  background-color: #0a3c63;
}
.img-txt {
  max-width: 400px;
  float: left;
  align-items: center;
  border: 3px solid #fff;
  border-radius: 10px;
  margin-right: 25px;
}

.text-box {
  color: #fff;
  align-items: center;
  justify-content: center;
}

.text-box h2 {
  font-size: 42px;
}

.text-box p {
  font-size: 16px;
}
/*SSG Rockstars are the cream of crop*/
.container-3 {
  width: 100%;
  min-height: 10vh;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 90px;
}

.content-section {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.content-section .card {
  flex: 1;
  margin: 20px 20px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.2);
}
.content-section img {
  width: 100%;
  height: auto;
}

.content-section .card h2 {
  margin: 15px 0px;
  font-size: 28px;
  color: #1662a1;
}

.best-txt {
  text-align: center;
  color: #d3d3d3;
}

.content-section .card p {
  font-size: 16px;
  line-height: 1.5;
  padding: 0px 10px;
  margin-bottom: 20px;
}
/*image overlayed#bdb6b5*/
.bigimage {
  width: 100%;
  height: 50vh;
  position: relative;
  background-color: #1669ad;
  background-size: cover;
}

.overlay {
  width: 100%;
  height: 100%;
  font-size: 26px;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay p{
  font-size: 20px;
}

/*Logo of company partners*/
.container-4 {
  width: 100%;
  height: 25vh;
  display: grid;
  place-items: center;
  font-family: "Titillium Web", sans-serif;
}

.client-wrap {
  width: 90%;
  height: auto;
  text-align: center;
}

.client-wrap h1 {
  font-size: 60px;
  color: #555;
}

.client-wrap hr {
  width: 15%;
  height: 5px;
  border: #26ccca;
  margin: auto;
  margin-bottom: 30px;
  margin-bottom: 30px;
}

.client-in {
  width: 100%;
  height: auto;
}

.client-in ul {
  padding: 0;
  margin: 0;
}

.client-in ul li {
  list-style: none;
  display: inline-block;
  width: 150px;
  height: 100px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  margin: 10px;
  box-shadow: 0px 0px 0px -7px #555;
  transition: 0.5s;
}

.client-in ul li img {
  width: 100%;
  height: 100%;
}

.client-in ul li img:nth-child(1) {
  transform: translateY(0);
  transition: 0.5s;
}

.client-in ul li img:nth-child(2) {
  transform: translateY(0);
  transition: 0.5s;
}

/*hover section*/
.client-in ul li:hover img:nth-child(1) {
  transform: translateY(-100%);
  transition: 0.5s;
}

.client-in ul li:hover img:nth-child(2) {
  transform: translateY(-100%);
  transition: 0.5s;
}
@media (max-width:400px) {
  .container-4 {
    height: 100%;
  }
  
}
@media (max-width:768px) {
  .nothin {
    display: none;
  }

  .client-in{
    margin-bottom: 50%;
  }

  .container-4 {
    min-height: 70vh;
  }
}
@media (max-width: 400px){
  .bigimage {
    
    height: 100%;
  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
  .nav-links-mobile {
    position: absolute;
    display: block;
    list-style: none;
    box-shadow: rgba(50, 50, 93, 0.23) 0 30px 30px -20px;
    left: 0;
    top: 10%;
    transition: all 0.5s ease-in-out;
    width: 100%;
    background-color: white;
  }

  ul li {
    padding: 32px;
    width: 100%;
    transition: all 0.5s ease-in-out;
    text-align: center;
  }

  .mobile-menu-icon {
    position: absolute;
    display: block;
    right: 20px;
  }
  /*Landing mobile*/
  .Drink-img {
    width: 100%;
    height: auto;
  }
  .Styles-text {
    /* position: absolute; */
    top: 10%;
    color: #1a54b0;
    padding: 20px;
    text-align: left;
    font: normal normal 800 32px/75px Proxima Nova;
    letter-spacing: 1px;
    text-transform: uppercase;
    opacity: 0.87;
  }

  .hospitality-text {
    position: absolute;
    top: 17%;
    padding: 20px;
    text-align: left;
    font: normal normal 800 20px/38px Proxima Nova;
    letter-spacing: 1.6px;
    color: #ff0050;
    text-transform: uppercase;
    opacity: 0.87;
  }

  .ContactUs-btn {
    margin-top: 4%;
    margin-left: 25%;
    position: absolute;
    cursor: pointer;
    top: 25%;
    color: #fff;
    font-family: bold;
    padding: 20px;
    border: 2px solid var(--unnamed-color-ff0050);
    background: #ff00504d 0% 0% no-repeat padding-box;
    border: 2px solid #ff0050;
    opacity: 1;
  }

  /*Tiles section*/
  .content-section {
    flex-direction: column;
  }

  .best-txt {
    text-align: center;
    color: #d3d3d3;
  }
  .bigimage {
    
    height: 70vh;
  }
  /*Logo images not to react*/
  
  
}
/*Footer */
footer{
  width: 100%;
  /*Add margin here*/
  margin-top: 15%;
  bottom: 0;
  background: linear-gradient(to right, #00093c, #2d0b00);
  color: #fff;
  padding: 100px 0 30px;
  border-top-left-radius: 125px;
  font-size: 13px;
  line-height: 20px;
}
.row {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.col{
  flex-basis: 25%;
  padding: 10px;
}
.col:nth-child(2), .col:nth-child(3){
  flex-basis: 15%;
}

.logo{
  width: 80px;
  margin-bottom: 30px;
}

.col h3{
  width: fit-content;
  margin-bottom: 40px;
  position: relative;
  color: #ff0050;
}

.email-id {
  width: fit-content;
  border-bottom: 1px solid #ccc;
  margin: 20px 0;
}

.ul li{
  list-style: none;
  margin-bottom: 12px;
}

.ul li a{
  text-decoration: none;
  color: #fff;
  
}
.social-icons .fab{
  width: 50px;
  height: 50px;
  border-radius: 80%;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #000;
  background: #fff;
  margin-right: 15px;
  cursor: pointer;
}
.social-icons .fab:hover{
  color: #ff0050;
}
hr{
  width: 90%;
  border: 0;
  border-bottom: 1pc solid #ccc;
  margin: 20px auto; 
}
.copyright {
  text-align: center;
}
.link-s{
  cursor: pointer;
}
.link-s:hover{
  color: #ff0050;
}
@media (max-width: 700px){
  footer{
    bottom: unset;
    margin-top: 50%;
  }
  .col{
    flex-basis: 100%;
  }
  .col:nth-child(2), .col:nth-child(3){
    flex-basis: 100%;
  }
}