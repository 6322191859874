@import url("https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap");

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.wrapper {
    font-family: 'raleway';
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fafafa;
}

.wrapper h1{
    font-size: 3rem;
    margin: 25px 0;
    text-align: center;
    font-weight: 600;
    color: rgba(255,0,82);
}
.container-22{
    min-height: 60vh;
}
.content-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1000px;
    margin-top: 30px;
}

.card {
    min-height: 220px;
    width: 320px;
    padding: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    margin: 10px 4px;
    box-shadow: 0px 15px 20px rgba(0,0,0,0.2);
    transition: 0.3s;
}

.card {
    margin: 20px;
    color: #ff5724;
    cursor: default;
}

.card h2{
    margin-bottom: 12px;
    font-weight: 400;
    text-align: center;
}

.card p{
    color: #6c757d;
    text-align: center;
}

.card i p{
    color: #fff;
    cursor: default;
}
.card:hover h2{
    font-weight: 500;
    color: #f2f2f2;
}
.card:nth-child(1):hover{
    background: linear-gradient(45deg,
    rgba(255,28,8,0.8) 0%,
    rgba(255,0,82,0.9) 100%,
    /* url('Hospility.jpg')*/);
    background-size: cover;
}
/*I must reapet this nth-child to fit every serv*/

/*Reponsive*/
@media(max-width: 991px){
    .wrapper{
        padding: 25px;
    }

    .wrapper h1{
        font-size: 2.5em;
        font-weight: 600;
    }
    .content-box {
        flex-direction: column;
        width: 100%;
    }

    .card{
        min-width: 300px;
        margin: 10px auto;
    }
}


